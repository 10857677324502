<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex">
        <span class="text-truncate">
          {{ cveid }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Vulnerability Details
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet
                v-for="(description, ind) in resolvePath('cve.description.description_data', cve)"
                :key="ind"
                color="lime lighten-4"
                class="pa-2 mb-2 mt-3"
                elevation="1"
              >
                <div class="text-body-1">
                  {{ description.value }}
                </div>
                <div class="grey--text text--darken-1 text-caption mt-1">
                  {{
                    "Publish Date : " +
                      cve.publishedDate.split("T")[0] +
                      " Last Update Date : " +
                      cve.lastModifiedDate.split("T")[0]
                  }}
                </div>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              CVSS Scores & Vulnerability Types
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CVSS Score</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.baseScore", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Confidentiality Impact</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.confidentialityImpact", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Integrity Impact</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.integrityImpact", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Availability Impact</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.availabilityImpact", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Access Complexity</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.accessComplexity", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Authentication</td>
                      <td>{{ resolvePath("impact.baseMetricV2.cvssV2.authentication", cve) }}</td>
                    </tr>
                    <tr>
                      <td>Gained Access</td>
                      <td>{{ gained }}</td>
                    </tr>
                    <tr>
                      <td>Vulnerability Type(s)</td>
                      <td>{{ type }}</td>
                    </tr>
                    <tr>
                      <td>CWE ID</td>
                      <td>{{ resolvePath("cve.problemtype.problemtype_data.0.description.0.value", cve) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Additional Vendor Supplied Data
            </v-expansion-panel-header>
            <v-expansion-panel-content> </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Related OVAL Definitions
            </v-expansion-panel-header>
            <v-expansion-panel-content> </v-expansion-panel-content>
          </v-expansion-panel> -->
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Products Affected By {{ cveid }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Product Type</th>
                      <th class="text-left">Vendor</th>
                      <th class="text-left">Product</th>
                      <th class="text-left">Version</th>
                      <th class="text-left">Update</th>
                      <th class="text-left">Edition</th>
                      <th class="text-left">Language</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="prod in products" :key="prod.uid">
                      <td>{{ prod.uid }}</td>
                      <td>{{ prod.type }}</td>
                      <td>
                        <router-link :to="'/vulnerabilities-search/vendor-' + prod.vendor">
                          {{ prod.vendor }}
                        </router-link>
                      </td>
                      <td>
                        <router-link :to="'/vulnerabilities-search/vendor-' + prod.vendor + '/product-' + prod.product">
                          {{ prod.product }}
                        </router-link>
                      </td>
                      <td>{{ prod.version }}</td>
                      <td>{{ prod.update }}</td>
                      <td>{{ prod.edition }}</td>
                      <td>{{ prod.language }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Number Of Affected Versions By Product
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table dense>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">Vendor</th>
                      <th class="text-left">Product</th>
                      <th class="text-left">Vulnerable Versions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ven in vendors" :key="ven.uid">
                      <td>
                        <router-link :to="'/vulnerabilities-search/vendor-' + ven.vendor">{{ ven.vendor }}</router-link>
                      </td>
                      <td>
                        <router-link :to="'/vulnerabilities-search/vendor-' + ven.vendor + '/product-' + ven.product">
                          {{ ven.product }}
                        </router-link>
                      </td>
                      <td>{{ ven.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              References For {{ cveid }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <template v-for="(ref, ind) in resolvePath('cve.references.reference_data', cve)">
                  <v-list-item :key="ind">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div>
                          <a :href="ref.url">{{ ref.url }}</a>
                        </div>
                        <div class="mt-2">{{ ref.refsource + " " + ref.name }}</div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="'div' + ind" />
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Vulnerability Conditions
            </v-expansion-panel-header>
            <v-expansion-panel-content> </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
              Metasploit Modules Related To {{ cveid }}
            </v-expansion-panel-header>
            <v-expansion-panel-content> </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  props: {
    recordId: { type: String, default: "" }
  },
  data() {
    return {
      cveid: "",
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cve: {},
      products: [],
      vendors: [],
      conditions: [],
      uid: 1,
      gained: "none",
      type: "",
      types: { a: "Application", o: "OS", h: "Hardware" }
    }
  },
  computed: {},
  watch: {
    $route() {
      this.$log("route changed ", this.$route)
      if (this.$route.name == "CVE") {
        this.cveid = this.$route.params.pathMatch
        this.getRecord()
      }
    },
    recordId() {
      this.$log("recordId ", this.recordId)
      if (this.recordId) {
        this.getRecord()
      }
    }
  },
  created() {
    this.$log("route created ", this.$route)
    this.cveid = this.$route.params.pathMatch
    if (this.recordId) {
      this.getRecord()
    } else {
      this.getRecord()
    }
  },
  methods: {
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    getRecord() {
      this.$log("getRecord")
      let where = '?where={"cve.CVE_data_meta.ID":{"$regex":"' + encodeURIComponent(this.cveid) + '","$options":"i"}}'
      if (this.recordId) {
        where = "/" + this.recordId
      }
      axios.get("nvd_cve" + where).then(response => {
        //this.$log("cve ", response)
        if (response.status == 200) {
          //this.$log("cve ", response.data._items[0])
          if (this.recordId) {
            this.cve = response.data
          } else {
            this.cve = response.data._items[0]
          }
          // this.cve = response.data._items[0]
          this.products = []
          this.uid = 0
          this.gained = "None"
          if (this.resolvePath("impact.baseMetricV2.obtainAllPrivilege", this.cve)) {
            this.gained = "Admin"
          } else if (this.resolvePath("impact.baseMetricV2.obtainUserPrivilege", this.cve)) {
            this.gained = "User"
          } else if (this.resolvePath("impact.baseMetricV2.obtainOtherPrivilege", this.cve)) {
            this.gained = "Other"
          }
          this.cve.configurations.nodes.map(nod => {
            nod.cpe_match.map(cpe => {
              this.addProd(cpe.cpe23Uri)
            })
            //   nod.children.map(child => {
            //     child.cpe_match.map(cpe => {
            //       this.addProd(cpe.cpe23Uri)
            //     })
            //   })
          })
          this.products.sort((a, b) => {
            return (
              a.vendor.localeCompare(b.vendor) ||
              a.product.localeCompare(b.product) ||
              a.version.localeCompare(b.version)
            )
          })
          this.vendors.sort((a, b) => {
            return a.vendor.localeCompare(b.vendor) || a.product.localeCompare(b.product)
          })
        }
      })
    },
    addProd(cpe) {
      const arr = cpe.split(":")
      if (this.vendors.find(itm => itm.vendor == arr[3] && itm.product == arr[4])) {
        this.vendors.find(itm => itm.vendor == arr[3] && itm.product == arr[4]).count++
      } else {
        this.vendors.push({ vendor: arr[3], product: arr[4], count: 1, uid: this.uid++ })
      }
      this.products.push({
        type: this.types[arr[2]],
        vendor: arr[3],
        product: arr[4],
        version: arr[5],
        update: arr[6],
        edition: arr[7],
        language: arr[8],
        uid: this.uid++
      })
    }
  }
}
</script>
<style>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 50px;
}
</style>
